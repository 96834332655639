import React from 'react'
import styles from './ScrollBlock.module.css'

export function useScrollBlock(active: boolean) {
  React.useEffect(() => {
    if (!window.document.body) return
    blockScrolling(active)
    return () => {
      blockScrolling(active)
    }
  }, [active])
}

function blockScrolling(active: boolean): void {
  if (active) {
    window.document.body.classList.add(styles.scrollBlock as string)
  } else {
    window.document.body.classList.remove(styles.scrollBlock as string)
  }
}
