'use client'
import { Container } from 'components/Container'
import { LogoWithName } from 'components/icons/Logo'
import { MenuIcon } from 'components/icons/Menu'
import { cx } from 'lib/cx'
import React from 'react'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import { CurtainWave } from './Wave'
import { Link, usePathname } from 'i18n/routing'
import { SlugTranslationsQuery } from '.generated/dato'
import { SlugTranslationsProvider } from './LanguageSwitcher'

export type HeaderProps = {
  hideWave?: boolean
  className?: string
  slugTranslations: SlugTranslationsQuery
}

export default function Header({
  hideWave,
  className,
  slugTranslations,
}: HeaderProps) {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const pathname = usePathname()

  React.useEffect(() => {
    setMenuIsOpen(false)
  }, [pathname])

  React.useEffect(() => {
    if (menuIsOpen && typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [menuIsOpen])

  return (
    <header className={cx(className, 'bg-background')}>
      <SlugTranslationsProvider value={slugTranslations}>
        <MobileNavigation isActive={menuIsOpen} />
        <Container className="flex flex-row justify-between items-end relative gap-16 py-16 lg:py-64">
          {!hideWave && <CurtainWave />}
          <Link
            href="/"
            className="flex-grow-0 relative lg:text-30 text-22 z-40"
            aria-label="farbenmeer"
          >
            <LogoWithName />
          </Link>
          <DesktopNavigation />
          <button
            className="relative lg:hidden z-20"
            onClick={() => setMenuIsOpen((open) => !open)}
            aria-label="Mobile Menu Toggle"
          >
            <MenuIcon isActive={menuIsOpen} />
          </button>
        </Container>
      </SlugTranslationsProvider>
    </header>
  )
}
