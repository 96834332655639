'use client'
import { cx } from 'lib/cx'
import React from 'react'
import styles from './styles.module.css'
import tailwindConfig from 'tailwind.config'

export const ANIMATION_DURATION = 0.8 // seconds
const CURTAIN_DELAY = 0.1 // seconds

function closeAbove(wave: string): string {
  return `M 0, 0 ${wave} V 0 Z`
}

function closeBelow(wave: string): string {
  return `M 0, 1000 ${wave} V 1000 Z`
}

function wave(dy: number) {
  return [
    // y      x1    dx1   dy1   dx2   dy2   x2
    'V 0    h 0   c 100,  0,    600,  0,    700, 0 H 1000',
    `V 500  h 250 c 100,  ${dy},  400,  -${dy}, 500, 0 H 1000`,
    'V 1000 h 300 c 100,  0,    600,  0,    700, 0 H 1000',
  ]
}

const variantsInTall = wave(200).map(closeAbove)
const variantsOutTall = wave(200).map(closeBelow)
const variantsOutWide = wave(400).map(closeBelow)

function getColor(variableName: string) {
  return window.getComputedStyle(document.body).getPropertyValue(variableName)
}

function setThemeColor(color: string) {
  document.head
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute('content', color)
}

export function BackgroundWave(
  props: React.PropsWithChildren<{ isActive: boolean }>,
) {
  const exitAnimationRef = React.useRef<
    (SVGAnimateElement & { beginElement: () => void }) | null
  >(null)

  React.useEffect(() => {
    if (props.isActive) {
      setThemeColor(
        getColor('--highlight-color') || tailwindConfig.theme.colors.orange,
      )
    } else {
      exitAnimationRef.current?.beginElement()
      setThemeColor(getColor('--background-color'))
    }
  }, [props.isActive])

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="300 10 400 980"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={cx(
        'text-wave',
        props.isActive && 'motion-reduce:bg-background',
      )}
    >
      <path
        d={variantsInTall[0]}
        fill="currentColor"
        stroke="invisible"
        className="motion-reduce:hidden transition-colors delay-1000 duration-700"
      >
        <animate
          attributeName="d"
          fill="freeze"
          dur={ANIMATION_DURATION}
          values={variantsInTall.join(';')}
          calcMode="spline"
          keySplines="0.42 0 1 1; 0 0 0.58 1"
        />
        <animate
          attributeName="d"
          fill="freeze"
          dur={ANIMATION_DURATION}
          values={variantsOutTall.join(';')}
          calcMode="spline"
          keySplines="0.42 0 1 1; 0 0 0.58 1"
          begin="indefinite"
          ref={exitAnimationRef}
        />
      </path>
    </svg>
  )
}

export function CurtainWave(props: { className?: string }) {
  React.useEffect(() => {
    window.setTimeout(() => {
      setThemeColor(getColor('--background-color'))
    }, CURTAIN_DELAY * 1000)
  }, [])

  return (
    <svg
      className={cx(
        props.className,
        styles.extraHigh,
        'h-[101vh] w-screen fixed -top-8 left-0 z-30 motion-reduce:hidden',
        'pointer-events-none text-wave',
      )}
      viewBox="300 10 400 980"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <CurtainWavePath className={styles.tall} variants={variantsOutTall} />
      <CurtainWavePath className={styles.wide} variants={variantsOutWide} />
    </svg>
  )
}

function CurtainWavePath(props: { className?: string; variants: string[] }) {
  return (
    <path
      d={props.variants[0]}
      stroke="transparent"
      fill="currentColor"
      className={props.className}
    >
      <animate
        attributeName="d"
        fill="freeze"
        dur={ANIMATION_DURATION}
        begin={CURTAIN_DELAY}
        values={props.variants.join(';')}
        calcMode="spline"
        keySplines="0.42 0 1 1; 0 0 0.58 1"
      />
    </path>
  )
}
