'use client'
import { ContactInfo } from 'components/ContactInfo'
import { cx } from 'lib/cx'
import { useScrollBlock } from 'lib/useScrollBlock'
import React, { Suspense } from 'react'
import tailwindConfig from 'tailwind.config'
import { FadingList } from './FadingList'
import { LanguageSwitcher } from './LanguageSwitcher'
import { LanguageSwitcherSkeleton } from './LanguageSwitcherSkeleton'
import { MenuItems } from './MenuItems'
import { ANIMATION_DURATION, BackgroundWave } from './Wave'
import styles from './styles.module.css'

export type MobileNavigationProps = {
  isActive: boolean
}

export default function MobileNavigation(props: MobileNavigationProps) {
  useScrollBlock(props.isActive)
  const [isVisible, setIsVisible] = React.useState(props.isActive)

  React.useEffect(() => {
    if (isVisible && !props.isActive) {
      const timeout = window.setTimeout(() => {
        setIsVisible(false)
      }, ANIMATION_DURATION * 1000)

      return () => {
        window.clearTimeout(timeout)
      }
    }

    if (!isVisible && props.isActive) {
      setIsVisible(true)
    }
  }, [props.isActive, isVisible])

  if (!isVisible) {
    return null
  }

  return (
    <div className="lg:hidden background-highlight text-black">
      <div className="absolute inset-0 z-20">
        <BackgroundWave isActive={props.isActive} />
      </div>
      <div className="fixed inset-0 grid landscape:grid-cols-2 landscape:grid-rows-6 gap-24 items-between pt-96 px-64 pb-64 z-20">
        <nav
          className="grid text-30 font-light"
          aria-label="Mobile Main Navigation"
        >
          <FadingList active={props.isActive}>
            <MenuItems />
          </FadingList>
        </nav>
        <div className="grid justify-start items-center landscape:col-start-2 landscape:row-start-5">
          <span
            className={cx(
              styles.mobileMenuFooter,
              props.isActive && styles.mobileMenuFooterActive,
            )}
          >
            <ContactInfo
              textColor={tailwindConfig.theme.colors.black}
              highlightColor={tailwindConfig.theme.colors.white}
              phone="+49 40 308 590 95"
              email="moin@farbenmeer.de"
            />
            <div className="absolute bottom-0 right-0 p-12 highlight-black">
              <Suspense fallback={<LanguageSwitcherSkeleton />}>
                <LanguageSwitcher />
              </Suspense>
            </div>
          </span>
        </div>
      </div>
    </div>
  )
}
