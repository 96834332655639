import { Suspense } from 'react'
import { LanguageSwitcher } from './LanguageSwitcher'
import { LanguageSwitcherSkeleton } from './LanguageSwitcherSkeleton'
import { MenuItems } from './MenuItems'

export default function DesktopNavigation() {
  return (
    <nav
      className="hidden lg:block text-18 font-regular tracking-11"
      aria-label="Main Navigation"
    >
      <ul className="grid gap-24 xl:gap-40 grid-flow-col" role="menu">
        <MenuItems />

        <li role="none">
          <Suspense fallback={<LanguageSwitcherSkeleton />}>
            <LanguageSwitcher />
          </Suspense>
        </li>
      </ul>
    </nav>
  )
}
