'use client'
import { usePathname } from 'next/navigation'
import { Button } from './Button'

export default function PreviewToolbar() {
  const pathname = usePathname()
  return (
    <section className="fixed bottom-32 max-w-full p-16 mx-32 bg-petrol rounded-md flex flex-row flex-wrap gap-16 items-center">
      You are in preview mode{' '}
      {pathname && (
        <a
          href={`/api/exit-preview?slug=${encodeURIComponent(
            pathname.slice(1),
          )}`}
        >
          <Button slim>Exit</Button>
        </a>
      )}
    </section>
  )
}
