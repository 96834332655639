import React from 'react'

const ANIMATION_DURATION = 0.2 // seconds

export type MenuIconProps = {
  isActive: boolean
}

type StartableAnimateElement = SVGAnimateElement & { beginElement: () => void }

export function MenuIcon(props: MenuIconProps) {
  const isShownActive = React.useRef<boolean>(false)
  const topActivateRef = React.useRef<StartableAnimateElement>(null)
  const topDeactivateRef = React.useRef<StartableAnimateElement>(null)
  const middleActivateRef = React.useRef<StartableAnimateElement>(null)
  const middleDeactivateRef = React.useRef<StartableAnimateElement>(null)
  const bottomActivateRef = React.useRef<StartableAnimateElement>(null)
  const bottomDeactivateRef = React.useRef<StartableAnimateElement>(null)

  React.useEffect(() => {
    if (isShownActive.current === props.isActive) {
      return
    }

    if (props.isActive) {
      topActivateRef.current?.beginElement()
      middleActivateRef.current?.beginElement()
      bottomActivateRef.current?.beginElement()
    } else {
      topDeactivateRef.current?.beginElement()
      middleDeactivateRef.current?.beginElement()
      bottomDeactivateRef.current?.beginElement()
    }

    isShownActive.current = props.isActive
  }, [props.isActive])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={15}
      viewBox="3 2 24 17"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title>Menu</title>
      <path d={topVariants.straight}>
        <animate
          attributeName="d"
          dur={ANIMATION_DURATION}
          values={`${topVariants.straight};${topVariants.diagonal}`}
          ref={topActivateRef}
          {...commonAnimateProps}
        />
        <animate
          attributeName="d"
          dur={ANIMATION_DURATION}
          values={`${topVariants.diagonal};${topVariants.straight}`}
          ref={topDeactivateRef}
          {...commonAnimateProps}
        />
      </path>
      <path d="M3 12 L27 12" strokeDasharray="24" strokeDashoffset={0}>
        <animate
          attributeName="stroke-dashoffset"
          dur={ANIMATION_DURATION * 0.8}
          values={'0;24'}
          ref={middleActivateRef}
          {...commonAnimateProps}
        />
        <animate
          attributeName="stroke-dashoffset"
          dur={ANIMATION_DURATION * 0.8}
          values={'24;0'}
          ref={middleDeactivateRef}
          {...commonAnimateProps}
        />
      </path>
      <path d={bottomVariants.straight}>
        <animate
          attributeName="d"
          dur={ANIMATION_DURATION}
          values={`${bottomVariants.straight};${bottomVariants.diagonal}`}
          ref={bottomActivateRef}
          {...commonAnimateProps}
        />
        <animate
          attributeName="d"
          dur={ANIMATION_DURATION}
          values={`${bottomVariants.diagonal};${bottomVariants.straight}`}
          ref={bottomDeactivateRef}
          {...commonAnimateProps}
        />
      </path>
    </svg>
  )
}

const topVariants = {
  straight: 'M3 6 L27 6',
  diagonal: 'M6 3 L21 18',
}
const bottomVariants = {
  straight: 'M3 18 L27 18',
  diagonal: 'M6 18 L21 3',
}

const commonAnimateProps = {
  fill: 'freeze',
  calcMode: 'spline',
  keySplines: '0.42 0 0.58 1',
  begin: 'indefinite',
}
