import { usePathname } from 'i18n/routing'
import { useTranslations } from 'next-intl'
import { MenuItem } from './MenuItem'

interface Props {
  className?: string
}

export function MenuItems({ className }: Props) {
  const pathname = usePathname()
  const t = useTranslations('nav')

  return (
    <>
      <MenuItem
        href="/kompetenzen"
        className={className}
        active={pathname.startsWith('/kompetenzen')}
      >
        {t('expertise')}
      </MenuItem>
      <MenuItem
        href="/projekte"
        className={className}
        active={pathname.startsWith('/projekte')}
      >
        {t('projects')}
      </MenuItem>
      <MenuItem
        href="/ueber-uns"
        className={className}
        active={pathname.startsWith('/ueber-uns')}
      >
        {t('aboutUs')}
      </MenuItem>
      <MenuItem
        href="/jobs"
        className={className}
        active={pathname.startsWith('/jobs')}
      >
        Jobs
      </MenuItem>
      <MenuItem
        href="/blog"
        className={className}
        active={pathname.startsWith('/blog')}
      >
        Blog
      </MenuItem>
      <MenuItem
        href="/kontakt"
        className={className}
        active={pathname.startsWith('/kontakt')}
      >
        {t('contact')}
      </MenuItem>
    </>
  )
}
