import { cx } from 'lib/cx'
import React from 'react'
import styles from './styles.module.css'

export function WideUnderline(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 119.359 3.723"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      preserveAspectRatio="none"
      height={3.723}
      width="100%"
      {...props}
    >
      <path
        data-name="Pfad 2683"
        d="M1.47.632a189.011 189.011 0 0 1 25.337.03 211.511 211.511 0 0 0 25.371.772c1.202-.048 2.411-.137 3.615-.223a48.726 48.726 0 0 1 7.903-.208 18.476 18.476 0 0 0 3.279-.11c5.012-.53 10.08-.085 15.133-.225 7.338-.226 14.71.682 22.067.65 3.506-.017 7.042.237 10.468-.517.974-.22 1.89-.552 2.865-.773.848-.167 1.56.423 1.795 1.42.21.762-.2 1.785-.793 1.926a1.821 1.821 0 0 1-.638.107c-7.555-.183-15.151-.361-22.71-.582-6.035-.148-12.087-.45-18.113-.519-3.515-.06-7 .15-10.538.25-6.474.212-12.899.456-19.34.982a91.235 91.235 0 0 1-10.839-.11c-5.395-.254-10.736-.788-16.115-.886a110.666 110.666 0 0 0-16.073.755 19.728 19.728 0 0 1-2.629.122 2.133 2.133 0 0 1-.94-.333 1.218 1.218 0 0 1-.533-1.352A1.462 1.462 0 0 1 .6.926c.19-.184.536-.18.872-.294z"
        fill="currentColor"
        strokeWidth={1.00052}
        className="transition-colors duration-700"
      />
    </svg>
  )
}

export function SlimUnderline(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      viewBox="0 0 219 23"
      height={3.723}
      fill="none"
      width="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.782 16.92c-24.26-3.45-45-1.66-65.74.41-4.31.43-8.71 1.49-12.9-.88-1.86-1.05-3.06-3.15-3.15-6.01-.08-2.93 1.28-4.84 2.9-6.32.77-.7 1.789-1.16 2.709-1.25 8.66-.91 17.33-2.58 25.98-2.38 23.19.54 46.41.989 69.541 3.149 27.03 2.53 54.08 2.79 81.12 3.85 7.73.3 15.459.01 23.189.2 2.87.07 5.78.41 8.59 1.19 1.99.55 3.3 2.72 3.32 5.68.02 2.87-1.16 4.87-3.04 6.1-3.21 2.1-6.609 1.38-9.979 1.29-18.84-.48-37.661-1.1-56.481-2.92-18.78-1.81-37.669-1.51-56.52-2.08-4.32-.14-8.67-.03-9.54-.03Z"
        fill="currentColor"
        className="transition-colors duration-700"
      />
    </svg>
  )
}

export function AnimatedUnderline(props: {
  isActive: boolean
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={cx(
        styles.underline,
        props.isActive && styles.underlineActive,
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
